import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { BrandInfoWithProducts, FilterInputs } from 'entries/brands';
import { Arguments, Response as BrandsState } from 'api/brands/Models';
import { getBrandsApi } from 'api/brands/getBrands';
import { getBrandById } from 'api/brands/getBrandById';

export const setBrandSearch = createAction<FilterInputs>('brands/findBrand');
export const clearBrandsSearch = createAction('brands/clearBrandSearch');
export const clearBrandsInfo = createAction('brands/clearBrandsInfo');

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchBrandById = createAsyncThunk<
  BrandInfoWithProducts,
  string | number,
  AsyncThunkOptions
>('brands/fetchBrandById', async (brandId, { rejectWithValue }) => {
  try {
    const result = await getBrandById(brandId);
    const brand: any = {
      ...result,
      xeroAccountId:
        result.xeroAccountId !== 'null' ? result.xeroAccountId : null,
    };
    return brand;
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});

export const fetchAllBrands = createAsyncThunk<
  BrandsState,
  Arguments,
  { rejectValue: number }
>('brands/fetchAllBrands', async (queryParams, { rejectWithValue }) => {
  try {
    return await getBrandsApi(queryParams);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});

export const setActiveBrand = createAction<BrandInfoWithProducts | null>(
  'brands/setActiveBrand',
);

export const changeStatus = createAction('brands/changeStatus');

export const changeURLBrand = createAction('brands/changeURLBrand');

export const resetActiveBrandError = createAction(
  'brands/resetActiveBrandError',
);
