export enum NewBrandsField {
  BrandsName = 'brandName',
  Currency = 'currencyCode',
  Country = 'countryCode',
  BrandImage = 'logoImage',
  BrandCardImage = 'cardImage',
  IsActive = 'isActive',
  Terms = 'terms',
  Description = 'description',
  Instructions = 'redemptionInstructions',
  Login = 'login',
  Password = 'password',
  XeroAccountId = 'xeroAccountId',
  IsUrlBrand = 'isUrlBrand',
  CategoryIds = 'categoryIds',
  IsCategoryChanged = 'isCategoryChanged',
  MarketPlaceResultType = 'marketPlaceResultType',
  Sku = 'sku',
}

export interface BrandInputs {
  [NewBrandsField.BrandsName]: string;
  [NewBrandsField.Currency]: string;
  [NewBrandsField.Country]: string;
  [NewBrandsField.BrandImage]: any;
  [NewBrandsField.BrandCardImage]: any;
  [NewBrandsField.IsActive]: boolean;
  [NewBrandsField.Terms]: string | null;
  [NewBrandsField.Description]: string | null;
  [NewBrandsField.Instructions]: string | null;
  [NewBrandsField.Login]: string;
  [NewBrandsField.Password]: string;
  [NewBrandsField.XeroAccountId]: string | null;
  [NewBrandsField.IsUrlBrand]: boolean;
  [NewBrandsField.CategoryIds]?: number[];
  [NewBrandsField.IsCategoryChanged]?: boolean;
  [NewBrandsField.MarketPlaceResultType]?: string;
  [NewBrandsField.Sku]?: string | null;
}
