import { combineReducers } from 'redux';
import { brands } from 'store/brands/reducer';
import { filter } from 'store/filters/reducer';
import { clients } from 'store/clients/reducer';
import { suppliers } from 'store/suppliers/reducer';
import { catalogs } from 'store/catalogs/reducer';
import { order } from 'store/order/reducer';
import { ordersHistory } from 'store/ordersHistory/reducer';
import { ordersHistoryClient } from 'store/ordersHistoryClient/reducer';
import { resetPassword } from 'store/resetPassword/reducer';
import { catalogClients } from 'store/catalog/catalogClients/reducer';
import { currentPageName } from 'store/currentPageName/reducer';
import { clientCatalog } from 'store/catalog/clientCatalog/reducer';
import { catalogProducts } from 'store/catalog/catalogProducts/reducer';
import { inactiveCatalogProducts } from 'store/catalog/inactiveCatalogProducts/reducer';
import { reactRouterHistory } from 'store/reactRouterHistory/reducer';
import { availableCatalogClients } from 'store/catalog/availableCatalogClients/reducer';
import { availableCatalogProducts } from 'store/catalog/availableCatalogProducts/reducer';
import { editableCatalogProducts } from 'store/catalog/editableCatalogProducts/reducer';
import { editableCatalogClients } from 'store/catalog/editableCatalogClients/reducer';
import { breadcrumbs } from 'store/breadcrumbs/reducer';
import { shoppingCarts } from 'store/shoppingCart/reducer';
import { allProductInventory } from 'store/inventory/allProductInventory/reducer';
import { soldProductInventory } from 'store/inventory/soldProductInventory/reducer';
import { activeProductInventory } from 'store/inventory/activeProductInventory/reducer';
import { reservedProductInventory } from 'store/inventory/reservedProductInventory/reducer';
import { editableProductInventory } from 'store/inventory/editableProductInventory/reducer';
import { integratedProducts } from 'store/products/integratedProducts/reducer';
import { nonIntegratedProducts } from 'store/products/nonIntegratedProducts/reducer';
import { editableIntegratedProducts } from 'store/products/editableIntegratedProducts/reducer';
import { editableNonIntegratedProducts } from 'store/products/editableNonIntegratedProducts/reducer';
import { users } from 'store/users/reducer';
import { analyticsReport } from 'store/analytics/reducer';
import { analyticsProductTable } from 'store/analyticsTable/reducer';
import { enebaMainTableProduct } from './eneba/mainProduct/reducer';
import { enebaSelectTableProduct } from './eneba/selectProduct/reducer';
import { enebaSelectCategoryTable } from './eneba/selectCategory/reducer';
import { enebaEditableProduct } from './eneba/editableProduct/reducer';
import { enebaFetchAccountList } from './eneba/enebaAccountList/reducer';
import { reconciliationAccountsState } from './reconciliation/reducer';
import { gamivoMainTableProduct } from './gamivo/mainProduct/reducer';
import { gamivoSelectTableProduct } from './gamivo/selectProduct/reducer';
import { gamivoSelectCategoryTable } from './gamivo/selectCategory/reducer';
import { gamivoEditableProduct } from './gamivo/editableProduct/reducer';
import { integratedProductCatalogs } from 'store/products/integratedProductCatalogs/reducer';
import { brandCatalogs } from './products/brandCatalogs/reducer';
import { inventoryReports } from './inventoryReports/inventoryReportsList/reducer';
import { catalogItemState } from 'store/catalog/catalogItems/reducer';
import { cardsHistory } from 'store/cardsHistory/reducer';
import { inventoryInvoices } from './inventoryinvoices/reducer';
import { binanceRequest } from 'store/binanceRequest/reducer';
import { categories } from 'store/category/categoryList/reducer';
import { categoryBrands } from 'store/category/categoryBrands/reducer';
import { g2aSelectCategoryTable } from 'store/g2a/selectCategory/reducer';
import { g2aMainTableProduct } from 'store/g2a/mainProduct/reducer';
import { g2aSelectTableProduct } from 'store/g2a/selectProduct/reducer';
import { inventoryStatistics } from 'store/inventoryStatistics/reducer';
import { inventoryCards } from 'store/inventoryCards/reducer';
import { kinguinSelectCategoryTable } from 'store/kinguin/selectCategory/reducer';
import { kinguinMainTableProduct } from './kinguin/mainProduct/reducer';
import { kinguinSelectTableProduct } from './kinguin/selectProduct/reducer';
import { kinguinEditableProduct } from './kinguin/editableProduct/reducer';
import { reconciliationProcess } from 'store/reconciliationProcess/reducer';
import { brandSupplierMapping } from './brands/supplierMapping/reducer';
import { catalogBrands } from 'store/bambooCatalog/catalogBrands/reducer';
import { inactiveCatalogBrands } from 'store/bambooCatalog/inactiveCatalogBrands/reducer';
import { availableCatalogBrands } from 'store/bambooCatalog/availableCatalogBrands/reducer';
import { editableCatalogBrands } from 'store/bambooCatalog/editableCatalogBrands/reducer';
import { brandCatalog } from 'store/brands/brandCatalog/reducer';

export const rootReducer = combineReducers({
  brands,
  clients,
  filter,
  catalogs,
  order,
  ordersHistory,
  ordersHistoryClient,
  suppliers,
  resetPassword,
  clientCatalog,
  catalogClients,
  catalogProducts,
  currentPageName,
  inactiveCatalogProducts,
  reactRouterHistory,
  availableCatalogClients,
  availableCatalogProducts,
  editableCatalogProducts,
  editableCatalogClients,
  breadcrumbs,
  shoppingCarts,
  allProductInventory,
  soldProductInventory,
  activeProductInventory,
  reservedProductInventory,
  editableProductInventory,
  integratedProducts,
  nonIntegratedProducts,
  editableIntegratedProducts,
  editableNonIntegratedProducts,
  users,
  analyticsReport,
  analyticsProductTable,
  enebaMainTableProduct,
  enebaSelectTableProduct,
  enebaSelectCategoryTable,
  enebaEditableProduct,
  enebaFetchAccountList,
  reconciliationAccountsState,
  gamivoMainTableProduct,
  gamivoSelectTableProduct,
  gamivoSelectCategoryTable,
  gamivoEditableProduct,
  integratedProductCatalogs,
  brandCatalogs,
  inventoryReports,
  catalogItemState,
  cardsHistory,
  inventoryInvoices,
  binanceRequest,
  categories,
  categoryBrands,
  g2aSelectCategoryTable,
  g2aMainTableProduct,
  g2aSelectTableProduct,
  inventoryStatistics,
  inventoryCards,
  kinguinSelectCategoryTable,
  kinguinMainTableProduct,
  kinguinEditableProduct,
  kinguinSelectTableProduct,
  reconciliationProcess,
  brandSupplierMapping,
  catalogBrands,
  inactiveCatalogBrands,
  availableCatalogBrands,
  editableCatalogBrands,
  brandCatalog,
});

export type RootState = ReturnType<typeof rootReducer>;
