import { createReducer } from '@reduxjs/toolkit';
import { BrandsFilterField } from 'entries/brands';
import { BrandsState } from './Models';
import {
  changeStatus,
  clearBrandsInfo,
  fetchAllBrands,
  fetchBrandById,
  resetActiveBrandError,
  setActiveBrand,
  setBrandSearch,
  changeURLBrand,
  clearBrandsSearch,
} from 'store/brands/actions';

export const initialState: BrandsState = {
  filter: {
    [BrandsFilterField.Brands]: '',
    [BrandsFilterField.Search]: '',
    [BrandsFilterField.Country]: '',
    [BrandsFilterField.Currency]: '',
    [BrandsFilterField.SuppliersId]: '',
    [BrandsFilterField.IntegrationType]: '',
  },
  brands: {
    pageSize: 50,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  isLoading: true,
  errors: {
    brandsList: null,
    activeBrand: null,
  },
  activeBrand: {
    isLoading: true,
    brand: null,
  },
};

export const brands = createReducer(initialState, (builder) => {
  builder
    .addCase(setBrandSearch, (state, action) => {
      state.filter = action.payload;
    })
    .addCase(clearBrandsSearch, (state) => {
      state.filter = initialState.filter;
    })
    .addCase(clearBrandsInfo, (state) => {
      state.brands = initialState.brands;
      state.isLoading = true;
      state.errors.brandsList = null;
    })
    .addCase(setActiveBrand, (state, action) => {
      state.activeBrand.brand = action.payload;
    })
    .addCase(changeStatus, (state) => {
      if (state.activeBrand.brand) {
        state.activeBrand.brand.isActive = !state.activeBrand.brand.isActive;
      }
    })
    .addCase(changeURLBrand, (state) => {
      if (state.activeBrand.brand) {
        state.activeBrand.brand.isUrlBrand = !state.activeBrand.brand
          .isUrlBrand;
      }
    })
    .addCase(resetActiveBrandError, (state) => {
      state.errors.activeBrand = null;
    })
    .addCase(fetchAllBrands.pending, (state, action) => {
      state.isLoading = true;
      state.errors.brandsList = null;
    })
    .addCase(fetchAllBrands.fulfilled, (state, action) => {
      state.isLoading = false;
      state.brands = {
        ...action.payload,
        items: state.brands.items.concat(action.payload.items),
      };
    })
    .addCase(fetchAllBrands.rejected, (state, action) => {
      state.isLoading = false;
      state.errors.brandsList = action.payload || 500;
    })
    .addCase(fetchBrandById.pending, (state, action) => {
      state.activeBrand.isLoading = true;
      state.errors.activeBrand = null;
    })
    .addCase(fetchBrandById.fulfilled, (state, action) => {
      state.activeBrand.isLoading = false;
      state.activeBrand.brand = action.payload;
    })
    .addCase(fetchBrandById.rejected, (state, action) => {
      state.isLoading = false;
      state.errors.activeBrand = action.payload || 500;
    });
});
