export const routes = {
  index: '/',
  brandsLanding: '/',
  signInLanding: '/signin',
  ordersLanding: '/orders',
  clientsLanding: '/clients',
  catalogsLanding: '/catalogs',
  catalogsClient: '/catalog',
  productsLanding: '/products',
  suppliersLanding: '/suppliers',
  brands: '/brands',
  brandInformation: '/brands/:id',
  clientInformation: '/clients/:clientId',
  catalogInformation: '/catalogs/:catalogId',
  supplierInformation: '/suppliers/:supplierId',
  orderInformationLanding: '/orders/:orderId',
  resetPasswordLanding: '/reset-password',
  createNewPasswordLanding: '/create-new-password',
  resetPasswordLinkExpiredLanding: '/reset-password/link-expired',
  brandProducts: '/brands/:id/products',
  clientCatalog: '/clients/:clientId/catalogs/:catalogId',
  shoppingCartsLanding: '/shopping-carts',
  shoppingCartsInformation: '/shopping-carts/:shoppingCartId',
  xoXo: '/supplier-integration/xoxo/confirm-auth',
  invoices: '/invoices',
  inventoryBase: '/inventory',
  inventory: '/inventory/:sku/:id',
  usersLanding: '/users',
  usersInformation: '/my-profile',
  pageNotFound: '/page-not-found',
  maintenance: '/maintenance',
  analytics: '/analytics',
  financeOperation: '/finance-operation',
  reconciliation: '/reconciliation',
  eneba: '/eneba/:enebaId',
  gamivo: '/gamivo',
  home: '/home',
  myCompany: '/my-company',
  funding: '/funding',
  inventoryReports: '/inventory-reports',
  inventoryReportInformation: '/inventory-report/:id',
  productRulesLanding: '/product-rules',
  restoreCatalog: '/restore-catalog-items',
  resetCatalogItemsProduct: '/reset-catalog-items-product',
  cardsLanding: '/cards',
  clientReport: '/client-report',
  supplierReport: '/supplier-report',
  brandReport: '/brand-report',
  binanceRequests: '/binance-requests',
  categoriesLanding: '/categories',
  enebaAutoMap: '/eneba-auto-map/:enebaId',
  settings: '/settings',
  g2a: '/g2a',
  inventoryInvoices: '/inventory-invoices',
  inventoryProducts: '/inventory-products',
  inventoryStatistics: '/inventory-statistics',
  inventoryCards: '/inventory-cards',
  binanceOffers: '/binance-offers',
  kinguin: '/kinguin',
  reconciliationProcess: '/reconciliation-process',
  bambooBrandsLanding: '/bamboo-brands',
  bambooCatalogsLanding: '/bamboo-catalogs',
  bambooCatalogsInformation: '/bamboo-catalogs/:catalogId',
  shoppingCartsLandingV2: '/shopping-carts-v2',
  shoppingCartsInformationV2: '/shopping-carts-v2/:shoppingCartId',
  catalogsClientV2: '/catalog-v2',
  brandInformationV2: '/brandsV2/:id',
};
