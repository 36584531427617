import { createReducer } from '@reduxjs/toolkit';
import { ProductsFilterField } from 'entries/products';
import {
  fetchIntegratedProducts,
  saveIntegratedProductsFilterStatus,
  setIntegratedProductsSearch,
} from 'store/products/integratedProducts/actions';

import { State } from './Models';

export const initialState: State = {
  filter: {
    [ProductsFilterField.Brands]: '',
    [ProductsFilterField.Country]: '',
    [ProductsFilterField.Currency]: '',
    [ProductsFilterField.SuppliersId]: '',
    [ProductsFilterField.Denomination]: '',
    [ProductsFilterField.SuppliersName]: '',
    [ProductsFilterField.Search]: '',
  },
  count: 0,
  items: [],
  pageIndex: 0,
  pageSize: 0,
  error: null,
  isLoading: true,
  isFilterActive: false,
};

export const integratedProducts = createReducer(initialState, (builder) => {
  builder
    .addCase(setIntegratedProductsSearch, (state, action) => {
      state.filter = action.payload;
    })
    .addCase(fetchIntegratedProducts.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchIntegratedProducts.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        items: action.payload.items.map((product) => ({
          ...product,
          isValid: true,
          tableData: {
            checked: false,
          },
        })),
      };
    })
    .addCase(fetchIntegratedProducts.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    })
    .addCase(saveIntegratedProductsFilterStatus, (state, action) => {
      state.isFilterActive = action.payload;
    });
});
