import { CatalogsListFilterField } from 'entries/catalogs';
import { CatalogsState } from './Models';
import { createReducer } from '@reduxjs/toolkit';
import {
  fetchCatalogs,
  setActiveCatalog,
  setCatalogSearch,
  clearCatalogsInfo,
  clearCatalogsErrors,
  clearCatalogsFilters,
  fetchAvailableCatalogsForProduct,
  fetchAvailableCatalogsForBrand,
} from 'store/catalogs/actions';

export const initialState: CatalogsState = {
  filter: {
    [CatalogsListFilterField.Brand]: '',
    [CatalogsListFilterField.Search]: '',
    [CatalogsListFilterField.Country]: '',
    [CatalogsListFilterField.Client]: '',
    [CatalogsListFilterField.SuppliersId]: '',
  },
  catalogs: {
    pageSize: 50,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  isLoading: true,
  error: null,
  activeCatalog: null,
  availableCatalogsForProduct: {
    pageSize: 50,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  availableCatalogsForBrand: {
    pageSize: 50,
    pageIndex: 0,
    count: 0,
    items: [],
  },
};

export const catalogs = createReducer(initialState, (builder) => {
  builder
    .addCase(setActiveCatalog, (state, action) => {
      state.activeCatalog = action.payload;
    })
    .addCase(setCatalogSearch, (state, action) => {
      state.filter = { ...state.filter, ...action.payload };
    })
    .addCase(clearCatalogsFilters, (state) => {
      state.filter = initialState.filter;
    })
    .addCase(clearCatalogsInfo, (state) => {
      state.catalogs = initialState.catalogs;
      state.isLoading = true;
      state.error = null;
    })
    .addCase(clearCatalogsErrors, (state) => {
      state.error = null;
    })
    .addCase(fetchCatalogs.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchCatalogs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.catalogs = {
        ...action.payload,
        items: state.catalogs.items.concat(action.payload.items),
        count: action.payload.count,
      };
    })
    .addCase(fetchCatalogs.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    })
    .addCase(fetchAvailableCatalogsForProduct.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchAvailableCatalogsForProduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.availableCatalogsForProduct = {
        ...action.payload,
        items: action.payload.items,
        count: action.payload.count,
      };
    })
    .addCase(fetchAvailableCatalogsForProduct.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    })
    .addCase(fetchAvailableCatalogsForBrand.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchAvailableCatalogsForBrand.fulfilled, (state, action) => {
      state.isLoading = false;
      state.availableCatalogsForBrand = {
        ...action.payload,
        items: action.payload.items,
        count: action.payload.count,
      };
    })
    .addCase(fetchAvailableCatalogsForBrand.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    });
});
